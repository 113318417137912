.gallery-pop
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 4

    background: var(--clr-black-transparent)

    .gallery-pop-close
        position: absolute
        top: 1rem
        right: 1rem
        transition: opacity .25s

        z-index: 5

        &:hover
            opacity: .5
            cursor: pointer

    .gallery-pop-image
        max-width: 96vw
        max-height: calc(96vh - 2rem - 64px)

        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    .gallery-buttons
        display: block
