@use '../../style/font.sass' as font

nav
  position: relative
  width: 100%
  padding: 0 var(--padding-horizontal)
  background-color: var(--clr-white)
  color: var(--clr-black)

  z-index: 3

  a, button, span
    font-family: font.$SokolFugner, cursive !important

  #nav-logo
    display: grid
    align-items: center

    font-family: font.$SokolTyrs, cursive !important
    font-size: 1.7rem
    padding-left: 4rem

    background-image: url("images/sokol_symbol_S_p_RGB.png")
    background-size: 3.5rem
    transition: color .25s

    &:hover
      cursor: pointer
      color: var(--clr-gray)

  #nav-links a
    font-size: 1.4rem

  #nav-ukraine
    background-image: url(images/ukraine.png)

@media (min-width: 767px)
  nav
    min-height: 3rem
    border-bottom: 2px solid var(--clr-active)

    display: grid
    grid-template-columns: auto 1fr 3rem 3rem
    grid-auto-flow: column
    justify-content: space-between
    grid-gap: 0 1rem

    #nav-open
      display: none

    #nav-links
      width: 100%
      max-width: 40rem
      justify-self: end
      align-items: center

      display: inline-flex
      justify-content: space-evenly

    #nav-ukraine
      grid-column: 3

@media (max-width: 767px)
  nav
    min-height: 4rem
    background-color: var(--clr-white)
    overflow: scroll

    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 4rem 1fr

    #nav-open
      position: absolute
      top: .5rem
      right: 1.5rem
      width: 3rem
      height: 3rem

      background-image: url("./images/menu.png")

    &.active
      position: fixed
      height: 100%
      min-height: 100vh

      & ~ *
        display: none

      #nav-open
        background-image: url("./images/close.png")

      #nav-links
        display: grid

      #nav-ukraine
        display: block

    #nav-logo
      padding: 0 2.5rem 0 4rem
      justify-self: left

      display: flex
      align-items: center

    #nav-links
      display: none

      grid-template-columns: 1fr
      grid-gap: 1rem
      align-self: center

      a
        text-align: center
        padding: 1rem 0

    #nav-ukraine
      display: none

      position: absolute
      top: 4rem
      right: 1.5rem

      width: 3rem
      height: 3rem

  #nav-ukraine
    top: 7rem
