#schedule
    #schedule-table
        display: grid

        .schedule-breakPoints
            writing-mode: vertical-lr
            padding-bottom: .25rem
            font-size: .75rem
            text-align: right
            color: var(--clr-gray)
            grid-row: 1

            &.active
                color: var(--clr-active)
                z-index: 2

                &.current
                    color: var(--clr-selected)

        .schedule-breakPoints-line
            width: 1px
            background: var(--clr-semiwhite)
            transition: background .25s

            &.active
                background: var(--clr-active)
                z-index: 2

                &.current
                    background: var(--clr-selected)

        .schedule-day-line
            grid-column: 1 / -1
            height: 1px
            background: var(--clr-semiwhite)

        .schedule-day-text
            grid-column: 1

            font-size: .75rem
            padding-right: .25rem

            display: flex
            align-items: flex-start

        .schedule-object
            word-break: break-word
            text-align: center
            font-size: .75rem
            padding: .25rem 0
            background-color: var(--clr-active-transparent)
            transition: background-color .25s

            display: flex
            align-items: center
            justify-content: center

            border-left: 1px solid var(--clr-active)

            z-index: 1

            &:hover
                color: var(--clr-active)
                background-color: var(--clr-active-semi-transparent)

            &.current
                border-left-color: var(--clr-selected)
                background-color: var(--clr-selected-transparent)
                color: var(--clr-selected)

                &:hover
                    background-color: var(--clr-selected-transparent)
