#news
    display: grid
    grid-gap: 2rem

    > div
        display: grid
        grid-gap: .25rem
        padding-left: calc(1rem + 1px)

        border-left: 1px solid var(--clr-black)

        &:hover
            padding-left: 1rem
            border-left: 2px solid var(--clr-active)

        &.pin
            padding-left: 1rem
            border-left: 2px solid var(--clr-selected)

        span
            font-size: .75rem
