.form-container
    display: grid
    grid-template-columns: 1fr 3rem
    grid-gap: .5rem
    margin: .25rem 0

    > section
        display: grid
        grid-template-columns: repeat(auto-fit, 3rem)
        grid-auto-rows: 3rem
        align-content: center

        button
            &.form-action-update, &.form-action-save
                background-image: url(./images/light/update.svg)

            &.form-action-delete
                background-image: url(./images/light/delete.svg)

                &.clicked
                    background-image: url(./images/delete.svg) !important
                    background-size: 50%

            &.form-action-moveUp
                background-image: url(./images/light/arrow-up.svg)

            &.form-action-moveDown
                background-image: url(./images/light/arrow-down.svg)

body.dark .form-container > section button
    &.form-action-update, &.form-action-save
        background-image: url(./images/dark/update.svg)

    &.form-action-delete
        background-image: url(./images/dark/delete.svg)

    &.form-action-moveUp
        background-image: url(./images/dark/arrow-up.svg)

    &.form-action-moveDown
        background-image: url(./images/dark/arrow-down.svg)
