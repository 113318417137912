\:root
    --clr-black: rgb(15, 15, 15)
    --clr-black-transparent: rgba(15, 15, 15, .8)
    --clr-gray: rgb(75, 75, 75)
    --clr-white: rgb(250, 250, 250)
    --clr-offwhite: rgb(245, 245, 245)
    --clr-almostwhite: rgb(240, 240, 240)
    --clr-semiwhite: rgb(220, 220, 220)
    --clr-selected-transparent: rgba(45, 46, 135, .2)
    --clr-active: rgb(228, 5, 33)
    --clr-active-transparent: rgba(228, 5, 33, .1)
    --clr-active-semitransparent: rgba(228, 5, 33, .3)
    --clr-active-bold: rgb(200, 0, 30)
    --clr-selected: rgb(45, 46, 135)
    --clr-extra: rgb(207, 177, 126)

@media (prefers-color-scheme: dark)
    \:root
        --clr-black: rgb(250, 250, 250)
        --clr-gray: rgb(220, 220, 220)
        --clr-white: rgb(15, 15, 15)
        --clr-offwhite: rgb(20, 20, 20)
        --clr-almostwhite: rgb(25, 25, 25)
        --clr-semiwhite: rgb(50, 50, 50)
        --clr-selected-transparent: rgba(164, 165, 222, .4)

        body:has(section#administration-nav)
            --clr-black: rgb(15, 15, 15)
            --clr-gray: rgb(75, 75, 75)
            --clr-white: rgb(250, 250, 250)
            --clr-offwhite: rgb(245, 245, 245)
            --clr-almostwhite: rgb(240, 240, 240)
            --clr-semiwhite: rgb(220, 220, 220)
            --clr-selected-transparent: rgba(45, 46, 135, .2)
