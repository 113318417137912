#squads
    display: grid
    grid-gap: 1rem

    dl
        display: grid
        grid-gap: .5rem 1rem
        padding-bottom: calc(1rem + 1px)

        border-bottom: 1px solid var(--clr-black)

        &:hover
            border-bottom-color: var(--clr-active)

        &:last-of-type
            border-bottom: none
            padding-bottom: 1rem

        dt
            grid-column: 1 / -1
            font-family: "Sokol-Tyrs", cursive
            font-size: 1.5rem

        dd .gallery
            height: 17.5rem

        dd.squads-contact, dd.squads-time
            span
                display: block
                font-size: .75rem

@media (min-width: 767px)
    #squads dl
        grid-template-columns: auto 1fr
        grid-template-rows: auto auto auto auto auto

        &:hover
            padding-bottom: 1rem
            border-bottom-width: 2px

        dd.squads-gallery
            display: grid
            grid-column: 1
            grid-row-start: 2
            grid-row-end: 6

            &.active
                margin-right: 1rem

@media (max-width: 767px)
    #squads dl
        grid-template-columns: 1fr
