@media (min-width: 767px)
    #administration-akce .form-title
        grid-template-columns: 1fr auto auto

@media (max-width: 767px)
    #administration-akce .form-title
        grid-template-columns: 1fr 1fr

        section:first-child
            grid-column-start: 1
            grid-column-end: 3
