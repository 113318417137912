.gallery-buttons
    position: relative
    display: none
    top: calc(50% - 2rem)
    width: 100%
    z-index: 2

    .gallery-button
        position: absolute
        width: 2rem
        height: 4rem

        background-image: url(./images/arrow-back.png)
        left: 1rem

        &:hover
            background-size: 40%

        &.gallery-button-right
            left: unset
            right: 1rem
            background-image: url(./images/arrow-forward.png)

@media (max-width: 767px)
    .gallery-buttons
        display: block
