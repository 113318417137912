#administration-nav
    position: relative
    width: 100%
    padding: .25rem var(--padding-horizontal)
    background-color: var(--clr-active)
    color: var(--clr-white)

    display: grid
    justify-content: center

    a
        text-align: center

        &:last-of-type
            color: var(--clr-semiwhite)
            text-decoration: underline

        &:hover
            color: var(--clr-semiwhite)

@media (min-width: 767px)
    #administration-nav-holder
        display: inline-flex
        flex-wrap: wrap
        grid-gap: .5rem 2rem

@media (max-width: 767px)
    #administration-nav-holder
        display: grid
        grid-template-columns: 1fr
        grid-gap: .5rem 1rem
