$WorkSansPath: '../font/WorkSans'
$WorkSans: 'WorkSans-Regular'

$src: local(#{$WorkSans}), url("#{$WorkSansPath}/#{$WorkSans}.ttf") format('truetype'),

@font-face
    font-family: $WorkSans
    src: $src
    font-weight: normal
    font-style: normal

$MontserratPath: '../font/Montserrat'
$Montserrat: 'Montserrat-Regular'

$src: local(#{$Montserrat}), url("#{$MontserratPath}/#{$Montserrat}.ttf") format('truetype'),

@font-face
    font-family: $Montserrat
    src: $src
    font-weight: normal
    font-style: normal

$SokolFugnerPath: '../font/SokolFugner'
$SokolFugner: 'Sokol-Fugner'

$src: local(#{$SokolFugner}), url("#{$SokolFugnerPath}/#{$SokolFugner}.otf") format('truetype'),

@font-face
    font-family: $SokolFugner
    src: $src
    font-weight: normal
    font-style: normal

$SokolTyrsPath: '../font/SokolTyrs'
$SokolTyrs: 'Sokol-Tyrs'

$src: local(#{$SokolTyrs}), url("#{$SokolTyrsPath}/#{$SokolTyrs}.otf") format('truetype'),

@font-face
    font-family: $SokolTyrs
    src: $src
    font-weight: normal
    font-style: normal
