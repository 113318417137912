#administration-aktuality
    .administration-aktuality-checkBox:has(input[checked]) + section
        opacity: .5

    .administration-container
        border-left: 1px solid var(--clr-black)

        &:first-of-type
            border-left: none

        &.pin
            padding-left: 1rem
            border-left-width: 2px
            border-left-color: var(--clr-selected)

@media (min-width: 767px)
    #administration-aktuality .form-title
        grid-template-columns: 1fr auto auto

@media (max-width: 767px)
    #administration-aktuality .form-title
        grid-template-columns: 1fr 1fr

        section:first-child
            grid-column-start: 1
            grid-column-end: 3
