.gallery
    min-width: 15rem
    height: 100%
    align-self: center
    overflow: hidden
    background: var(--clr-black-transparent)

    &:hover .gallery-buttons
        display: block

    .gallery-images
        position: relative
        width: 100%
        height: 100%

        display: grid
        justify-items: center
        align-items: center

        > img
            position: absolute

            max-width: 100%
            max-height: 100%

            display: none

            &.active
                display: block
                animation: show .25s

            &.gallery-link:hover
                cursor: pointer

@keyframes show
    from
        opacity: 0
    to
        opacity: 1

@media (min-width: 767px)
    .gallery-images
        width: 15rem

@media (max-width: 767px)
    .gallery-images
        width: calc(100vw - var(--padding-horizontal) * 2)
