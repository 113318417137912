#administration-banner
    .gallery
        background-color: var(--clr-almostwhite)
        margin: 1rem 0
        height: 15rem

        .gallery-images
            width: 100%

        .gallery-button
            width: 5rem
            background-size: 1rem

            &:hover
                background-size: 30%
