#sponsors
    display: inline-flex
    flex-wrap: wrap
    grid-gap: 1rem
    justify-content: space-evenly

    width: 100%

    a
        width: 8rem
        height: 4rem
        background-size: contain
