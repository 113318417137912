.editor
    display: grid
    grid-template-rows: 1fr
    grid-gap: .5rem

    .quill
        background-color: var(--clr-offwhite)

    .editor-title
        font-size: .75rem
        color: var(--clr-gray)

    .ql-container
        height: unset
