.administration-data-new
    form
        display: grid
        grid-template-columns: 1fr 3rem

        input[type="submit"]
            background-image: url(./images/light/update.svg)

body.dark .administration-data-new form input[type="submit"]
    background-image: url(./images/dark/update.svg)
