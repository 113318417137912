.administration-data-edit
    .form input
        width: 100%

        > section
            align-self: center

    &.administraction-data-edit-document
        .administration-data-icon
            width: 100%
            height: 100%
            background-size: 70%

@media (min-width: 767px)
    .administration-data-edit
        .form-container
            grid-template-columns: 1fr 12rem

        &.administraction-data-edit-document .form
            grid-template-columns: 3rem 1fr auto

        &.administraction-data-edit-image .form
            grid-template-columns: 15rem 1fr 1fr

@media (max-width: 767px)
    .administration-data-edit
        .form-container
            grid-template-columns: 1fr 3rem

        .gallery .gallery-images
            width: calc(100vw - var(--padding-horizontal) * 2 - 2rem)

        &.administraction-data-edit-document .form
            grid-template-columns: 3rem 1fr

            .administration-data-icon
                grid-row-start: 1
                grid-row-end: 3

        &.administraction-data-edit-image .form
            grid-template-columns: 1fr
            min-height: 12rem
