@use '../../../../style/font.sass' as font

.events-calendar
    display: grid
    justify-content: space-between
    align-self: baseline

    button
        background-size: 1rem

        &:hover
            background-size: 1.5rem

        &.events-button-left
            background-image: url(./images/arrow-back.png)

        &.events-button-right
            background-image: url(./images/arrow-forward.png)

    dl
        display: grid

        dt
            font-size: 1.5rem
            font-family: font.$SokolTyrs
            justify-self: center

            display: inline-flex
            grid-gap: .5rem
            align-items: center

            span
                font-family: font.$SokolFugner

        dd
            display: grid

            &.events-daysInWeek
                border-bottom: 1px solid var(--clr-active)

                div
                    font-family: font.$SokolTyrs !important
                    font-size: .75rem
                    padding: .5rem 0

            div
                font-family: font.$SokolFugner, cursive !important
                text-align: center

                &:hover
                    cursor: pointer
                    color: var(--clr-selected)

                &.active
                    color: var(--clr-active) !important

                &.in
                    background-color: var(--clr-selected-transparent)

                &.today
                    background-image: url(./images/border.png)
                    background-repeat: no-repeat
                    background-position: center 80%

                &.off
                    opacity: 0.4

@media (min-width: 767px)
    .events-calendar
        grid-template-columns: 3rem 1fr 3rem

        dl
            justify-content: center

            dd
                grid-template-columns: repeat(7, 4rem)

                div
                    padding: 1rem

@media (max-width: 767px)
    .events-calendar
        grid-template-columns: 2rem 1fr 2rem

        div
            padding: 20%

        dl dd
            grid-template-columns: repeat(7, 1fr)

body.dark div.today
    background-image: url(./images/border-black.png) !important
