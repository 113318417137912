#administration-oddily
    .form .administration-oddily-timeAndContact
        display: grid
        grid-gap: 1rem
        align-items: baseline

    .gallery
        background-color: var(--clr-almostwhite)
        margin: 1rem 0
        height: 10rem

        .gallery-button
            width: 5rem
            background-size: 1rem

            &:hover
                background-size: 30%

@media (min-width: 767px)
    #administration-oddily .form .administration-oddily-timeAndContact
        grid-template-columns: 1fr 1fr

@media (max-width: 767px)
    #administration-oddily .form .administration-oddily-timeAndContact
        grid-template-columns: 1fr
