.documents
    grid-gap: 0 1rem

    p
        padding: .5rem 0 .5rem 2.5rem
        background-size: 2rem

        display: grid
        grid-template-columns: 1fr
        grid-template-rows: auto 1fr

    span
        font-size: .75rem

    a
        font-size: 1.2rem

@media (min-width: 767px)
    .documents
        display: inline-flex
        flex-wrap: wrap

@media (max-width: 767px)
    .documents
        display: grid
        grid-template-columns: 1fr
