footer
  position: relative
  width: 100%
  background: black
  color: white
  padding: 2rem var(--padding-horizontal)

  display: inline-flex
  justify-content: space-between
  flex-wrap: wrap
  grid-gap: 2rem

  dl
    dt
      text-decoration: underline
      padding: .5rem 0

    dd
      display: grid
      grid-gap: .25rem

      div
        display: grid
        grid-template-columns: 1fr

        span
          font-size: 0.75rem

      .documents
        max-width: 15rem

        p
          width: 100%
          padding-left: 1.5rem
          background-size: 1rem
