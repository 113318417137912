.loader
    display: grid
    grid-template-columns: 1fr

    img
        padding: 1rem 0
        width: 100%
        max-width: 15rem
        max-height: 100%
        justify-self: center
