@use "./font" as font

.administration-container
    margin: .5rem 0
    padding: 1rem
    background: var(--clr-almostwhite)

    opacity: 0
    animation: opacity .25s
    animation-fill-mode: forwards

    > *
        margin: .25rem 0

    h3
        font-size: .9rem
        color: var(--clr-gray)
        font-family: font.$SokolTyrs, cursive

@keyframes opacity
    0%
        opacity: 0
    100%
        opacity: 1

.form, form
    display: grid
    grid-template-columns: 1fr
    grid-gap: .5rem 1rem
    align-content: baseline
    align-items: baseline

    &.login
        justify-items: center

    input, .administraction-span
        width: 100%
        height: 100%
        background-color: var(--clr-offwhite)
        color: var(--clr-black)
        border: none
        padding: .5rem 1rem

        &:not([type="submit"])
            border-bottom: 1px solid var(--clr-black)

        &:focus
            border-block-color: var(--clr-gray)

        &[type="file"]
            border: none !important

        &[type="submit"]
            font-size: 1rem
            background-color: transparent
            justify-self: end
            padding: 0 1rem
            cursor: pointer

    section
        display: grid
        grid-template-columns: 1fr
        grid-gap: .25rem

        > span:first-child
            padding-left: .5rem
            font-size: .75rem

        input
            justify-self: center

    .form-title
        display: inline-grid
        grid-gap: 1rem

        section:first-of-type input
            font-weight: bold
            width: 100%

    .administraction-span
        font-size: .9rem
        border-bottom: none !important

#administration-nav ~ div
    display: grid
    grid-template-columns: 1fr

    > span
        font-size: 0.75rem
        padding-bottom: .25rem
        color: var(--clr-gray)

.administration-submit
    background-color: var(--clr-selected-transparent)
    color: var(--clr-selected)
    font-size: 1.2rem
    font-family: font.$SokolTyrs
    padding: .25rem 1rem
    border-radius: .25rem
    margin: .5rem 1rem
    justify-self: right
    transition: background-color .25s

    &:hover
        background-color: transparent

.year
    text-align: center
    font-size: 2rem

.month
    text-align: center
    font-size: 1.5rem
    border-bottom: 2px solid var(--clr-black)

.stop
    font-size: 1.75rem
    font-family: font.$SokolTyrs, cursive
    color: var(--clr-active)
    justify-self: center

    & ~ *
        display: none
