#banner
    padding: 0 !important
    height: 50vh
    min-height: 10rem

    .gallery-images
        width: 100%

    .gallery-button
        width: 5rem
        background-size: 1rem

        &:hover
            background-size: 30%
