@import "../../../../style/administration"

#administration-login
    min-height: calc(100vh - 3rem)

    display: grid
    justify-content: center
    align-content: center

    h2
        text-align: center

    input[type="password"]
        background: var(--clr-white)

    input[type="submit"]
        justify-self: center
