#events
    display: grid

    #events-list
        dl
            margin: 1rem
            border-left: 1px solid var(--clr-black)
            padding-left: calc(1rem + 1px)

            &.active
                border-left: 2px solid var(--clr-active) !important
                padding-left: 1rem

            &.today
                border-left: 2px solid var(--clr-selected)
                padding-left: 1rem

            .events-time
                font-size: .75rem

            dt
                font-size: 1.25rem
                margin-bottom: .5rem

            dd
                b
                    border-left: 1px solid var(--clr-black)
                    margin-left: .5rem
                    padding-left: .5rem

                .documents
                    margin: .25rem 0

        span
            display: block
            padding: 1rem
            width: 100%
            text-align: center

@media (min-width: 1201px)
    #events
        grid-template-columns: auto 1fr !important

        h2
            grid-column: 1 / -1

        .events-calendar
            position: -webkit-sticky
            position: sticky
            top: 1rem

@media (max-width: 1201px)
    #events
        grid-template-columns: 1fr

@media (min-width: 767px)
    #events #events-list dl
        margin: 1rem

@media (max-width: 767px)
    #events #events-list dl
        margin: 1rem 0
