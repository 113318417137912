@use './font.sass' as font

body
  background: var(--clr-white)

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: font.$WorkSans, font.$Montserrat, sans-serif

.icon, .icon-logo
  background-repeat: no-repeat
  transition: background-size .25s

.icon
  background-position: center
  background-color: transparent
  background-size: 60%
  transition: background-size .2s

  &:hover
    background-size: 50%
    cursor: pointer

.icon-logo
  padding-left: 2rem
  background-size: 1.5rem
  background-position: .25rem center

a, button
  text-decoration: none
  text-align: left
  font-size: 1rem

  border: none
  background-color: transparent
  padding: 0
  color: inherit
  transition: color .25s

  &:hover
    cursor: pointer
    color: var(--clr-active-bold)

  &.active
    color: var(--clr-active)

.sections
  display: inline-flex
  align-items: center
  flex-wrap: wrap
  grid-gap: .5rem

  a, button
    font-family: font.$SokolTyrs, cursive
    color: var(--clr-black)

    &:hover
      color: var(--clr-active)

    &::before
      content: ""
      width: 8px
      height: 8px
      border-radius: 50%
      margin-right: 1rem
      background-color: var(--clr-black)

      display: inline-block
      vertical-align: middle
#root
  > main
    display: grid

    min-height: 80vh

    span
      font-size: .8rem

  > div, > main
    padding: 2rem var(--padding-horizontal)
    color: var(--clr-black)
    background-color: var(--clr-white)

    &:nth-child(even)
      background-color: var(--clr-almostwhite)

    > h1
      min-width: 100%
      font-family: font.$SokolFugner, cursive !important
      font-size: 2rem
      margin: 1.5rem 0
      color: var(--clr-active)

      display: inline-flex
      grid-gap: 1rem
      flex-wrap: wrap

    > h2, > section h2
      font-family: font.$SokolTyrs, cursive
      margin: 1rem 0

    > h3, > h4, > h5, > section h3, > section, h4, > section h5
      font-family: font.$SokolTyrs, cursive
      margin: .25rem 0

@media (min-width: 767px)
  :root
    --padding-horizontal: 10vw

@media (max-width: 767px)
  :root
    --padding-horizontal: 2rem
